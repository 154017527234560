<template>
  <div>
    <v-simple-table
      fixed-header
      :height="tableHeight"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" style="width: 150px">
              <InputFilter
                :label="$t('labels.shift')"
                :placeholder="$t('labels.shift')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 150px" v-if="!idPos">
              <SelectFilter
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                :options="posOptions"
                name="pos_code"
                sort-name="pos_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkin_start") }}
            </th>
            <th role="columnheader" class="text-center primary--text">
              {{ $t("labels.start") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkin_end") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkout_start") }}
            </th>
            <th role="columnheader" class="text-center error--text">
              {{ $t("labels.end") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkout_end") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 100px">
              <v-btn
                color="primary"
                small
                @click="showCreateDialog({})"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`${item.id}_${index}`"
            class="text-center"
          >
            <td>{{ item.name }}</td>
            <td>
              <div style="white-space: pre">{{ item.pos_codes }}</div>
            </td>
            <td>{{ item.checkin_start }}</td>
            <td>{{ item.start }}</td>
            <td>{{ item.checkin_end }}</td>
            <td>{{ item.checkout_start }}</td>
            <td>{{ item.end }}</td>
            <td>{{ item.checkout_end }}</td>
            <td>
              <template v-if="item.status === 1">
                <v-btn
                  x-small
                  color="warning"
                  @click="showCreateDialog(item)"
                  class="mr-1"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <v-btn x-small color="error" @click="cancelWorkingTime(item)">
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
              <div v-else :class="item.status_color">
                {{ item.status_text }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>

    <v-dialog v-model="createDialog" max-width="600px" persistent>
      <WorkingTimeCreate
        v-if="createDialog"
        :edit-item="editItem"
        @cancel="hideCreateDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "WorkingTimeList",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    WorkingTimeCreate: () => import("@/components/pos/WorkingTimeCreate"),
  },
  props: {
    idPos: {
      type: [String, Number],
      default: () => null,
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    posOptions: [],
    items: [],
    filters: {},
    isLoading: false,
    createDialog: false,
    editItem: {},
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    tableHeight() {
      return this.idPos ? "calc(100vh - 265px)" : "calc(100vh - 205px)";
    },
    statusOptions() {
      return [
        {
          text: "Active",
          color: "success--text",
          value: 1,
        },
        {
          text: "Inactive",
          color: "error--text",
          value: 0,
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    if (this.idPos) {
      this.filters = { ...this.filters, id_pos: this.idPos };
    }
  },
  mounted() {
    this.getPos();
    this.getList();
  },
  methods: {
    showCreateDialog(item) {
      this.editItem = { ...item };
      this.createDialog = true;
    },
    hideCreateDialog() {
      this.createDialog = false;
    },
    async getPos() {
      if (this.idPos) {
        return false;
      }
      const { data } = await httpClient.post("/pos-all-active");
      this.posOptions = data.map((e) => ({
        value: e.code,
        text: e.code,
      }));
    },
    cancel() {
      this.$emit("cancel");
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_pos: this.idPos || null,
        index: null,
        name: null,
        start: null,
        end: null,
        checkout_start: null,
        checkout_end: null,
        checkin_start: null,
        checkin_end: null,
        status: 1,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-save-working-time", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async cancelWorkingTime(item) {
      if (!confirm(this.$t("messages.sure_to_cancel"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-cancel-working-time", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getStatusText(item) {
      return this.statusOptions.find((p) => p.value === item.status) || {};
    },
    getList: debounce(function () {
      httpClient
        .post("/pos-working-time-list", { ...this.filters })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((r) => {
            const status = this.getStatusText(r);
            r.status_text = status.text;
            r.status_color = status.color;
            return r;
          });
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
